<template>
  <div class="content">
    <div class="top">
      <a-input
          allowClear
          v-model="params.applyNo"
          class="w-200"
          placeholder='鉴定单号'
      ></a-input>
      <a-input
          allowClear
          v-model="params.shortCode"
          class="w-200 ml-5"
          placeholder='短码'
      ></a-input>
      <a-input
          allowClear
          v-model="params.ratingCode"
          class="w-200 ml-5"
          placeholder='评级编码'
      ></a-input>
      <a-input
          allowClear
          v-model="params.userName"
          class="w-200 ml-5"
          placeholder='用户名称或ID'
      ></a-input>
      <a-select
          class="w-200 ml-5"
          v-model="params.reviewStatus"
          placeholder="确认状态"
      >
        <a-select-option
            v-for="item of confirmTypeList"
            :key="item.dTypeValue"
            :value="item.dTypeValue"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-button
          type="primary"
          @click="search"
      >搜索</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          @changePage="handleChangePage"
          @success="getList"
      />
    </div>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/checkManage/DRateResultList/_components/TableList"
import {CONFIRM_TYPE_LIST} from "@/views/cmsPage/checkManage/_data"
import {destroyKeyPress, scanCodeWatch} from "@/untils/otherEvent"
import {getDTypeRateServiceResultList} from "@/views/cmsPage/checkManage/_apis"

export default {
  components: {
    TableList,
  },
  data() {
    return {
      data: [],
      confirmTypeList: CONFIRM_TYPE_LIST,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        applyNo: undefined,
        barCode: undefined,
        ratingCode: undefined,
        userName: undefined,
        // 确认状态
        reviewStatus: 0,
      },
    };
  },
  async mounted() {
    const { applyNo, reviewStatus } = this.$route.query
    this.$set(this.params, "applyNo", applyNo)
    this.$set(this.params, "reviewStatus", reviewStatus)
    await this.getList()
    scanCodeWatch(this.handleScanCodeGetData)
  },
  beforeDestroy() {
    destroyKeyPress()
  },
  methods: {
    /** 扫码录入数据 */
    handleScanCodeGetData(code) {
      this.params.ratingCode = code
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },
    /** 搜索 */
    async search() {
      this.$set(this.pagination, "current", 1);
      this.$set(this.params, "pageNum", 1);
      await this.getList();
      this.params.barCode = undefined
      this.params.ratingCode = undefined
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getDTypeRateServiceResultList(this.params)
      this.$loading.hide()
      if (res.status != 200) return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
